import dayjs from 'dayjs';

// Locales
import cs from 'dayjs/locale/cs';
import de from 'dayjs/locale/de';
import enGb from 'dayjs/locale/en-gb';

import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import nl from 'dayjs/locale/nl';
import pl from 'dayjs/locale/pl';
import ru from 'dayjs/locale/ru';
import tr from 'dayjs/locale/tr';
import zh from 'dayjs/locale/zh-cn';
import br from 'dayjs/locale/pt-br';

// Flag icons
import enFlag from '../../img/flags/us.svg';
import frFlag from '../../img/flags/fr.svg';
import cnFlag from '../../img/flags/cn.svg';
import esFlag from '../../img/flags/es.svg';
import deFlag from '../../img/flags/de.svg';
import itFlag from '../../img/flags/it.svg';
import ukFlag from '../../img/flags/gb.svg';
import ruFlag from '../../img/flags/ru.svg';
import nlFlag from '../../img/flags/nl.svg';
import plFlag from '../../img/flags/pl.svg';
import trFlag from '../../img/flags/tr.svg';
import czFlag from '../../img/flags/cz.svg';
import brFlag from '../../img/flags/br.svg';

// Apps localizations (lazy loaded, each l10n contains all lines)
import deLang from '@L10n/apps/de_DE.xml';
import frLang from '@L10n/apps/fr_FR.xml';
import esLang from '@L10n/apps/es_ES.xml';
import itLang from '@L10n/apps/it_IT.xml';
import brLang from '@L10n/apps/pt_BR.xml';
import nlLang from '@L10n/apps/nl_NL.xml';
import ruLang from '@L10n/apps/ru_RU.xml';
import plLang from '@L10n/apps/pl_PL.xml';
import zhLang from '@L10n/apps/zh_CN.xml';
import trLang from '@L10n/apps/tr_TR.xml';
import csLang from '@L10n/apps/cs_CZ.xml';

// Admin console localizations (lazy loaded, need to load english l10n first +
// corresponding l10n file)
import deAdminLang from '@L10n/admin/de_DE.json';
import frAdminLang from '@L10n/admin/fr_FR.json';
import esAdminLang from '@L10n/admin/es_ES.json';
import itAdminLang from '@L10n/admin/it_IT.json';
import brAdminLang from '@L10n/admin/pt_BR.json';
import ruAdminLang from '@L10n/admin/ru_RU.json';
import zhAdminLang from '@L10n/admin/zh_CN.json';

// Links localizations (static loaded, need to load english l10n first +
// corresponding l10n file)
import deLinks from '@L10n/links/de_DE.json';
import esLinks from '@L10n/links/es_ES.json';
import frLinks from '@L10n/links/fr_FR.json';
import itLinks from '@L10n/links/it_IT.json';
import nlLinks from '@L10n/links/nl_NL.json';
import ptLinks from '@L10n/links/pt_BR.json';
import ruLinks from '@L10n/links/ru_RU.json';

// Phone links l10ns
import dePhoneLinks from '@L10n/phones/de.json';
import frPhoneLinks from '@L10n/phones/fr.json';
import esPhoneLinks from '@L10n/phones/es.json';
import itPhoneLinks from '@L10n/phones/it.json';
import ptPhoneLinks from '@L10n/phones/pt.json';
import ruPhoneLinks from '@L10n/phones/ru.json';

import { TranslateService } from '@ngx-translate/core';
export * as enPhoneLinks from '@L10n/phones/en.json';

export interface Lang {
    readonly id: string;
    readonly phoneSystemId: string;
    readonly value: string;
    readonly phoneLinks?: string;
    readonly data: string[];
    readonly flag: string|undefined;
}

export const languageFlags: {[id: string]: string} = {
    en: enFlag,
    'en-gb': ukFlag,
    de: deFlag,
    fr: frFlag,
    es: esFlag,
    it: itFlag,
    // Note that due to a mistake chattemplate_language contains 'pt' and not 'pt-br'
    pt: brFlag,
    nl: nlFlag,
    ru: ruFlag,
    pl: plFlag,
    'zh-cn': cnFlag,
    tr: trFlag,
    cs: czFlag,
    // // Extra langs - to remove later
    // ja: jaFlag,
    // nb: nbFlag,
    // pt: ptFlag,
    // br: brFlag,
};

/**
 * Please note:
 * enLinks is automatically included and other *Links are added only if presented and contain only overrides
 * enAdminLang is automatically included and other *AdminLangs are added only if presented
 * Reason is though *AdminLang always contain all fields enAdminLang can contain new fields if out of sync with Transifex.
 * (so this is a small workaround)
 * On the other hand loader handles *Lang files so they will always be in sync so here enLang is not needed.
 */
export const languageList: Lang[] = [
    {
        id: 'en', phoneSystemId: 'EN', value: 'English (US)', data: [], flag: enFlag
    },
    {
        id: 'en-gb', phoneSystemId: 'UK', value: 'English (UK)', data: [], flag: ukFlag
    },
    {
        id: 'de', phoneSystemId: 'DE', value: 'Deutsch', phoneLinks: dePhoneLinks, data: [deLang, deAdminLang, deLinks], flag: deFlag
    },
    {
        id: 'fr', phoneSystemId: 'FR', value: 'Français', phoneLinks: frPhoneLinks, data: [frLang, frAdminLang, frLinks], flag: frFlag
    },
    {
        id: 'es', phoneSystemId: 'ES', value: 'Español', phoneLinks: esPhoneLinks, data: [esLang, esAdminLang, esLinks], flag: esFlag
    },
    {
        id: 'it', phoneSystemId: 'IT', value: 'Italiano', phoneLinks: itPhoneLinks, data: [itLang, itAdminLang, itLinks], flag: itFlag
    },
    {
        id: 'pt-br', phoneSystemId: 'PT', value: 'Português (BR)', phoneLinks: ptPhoneLinks, data: [brLang, brAdminLang, ptLinks], flag: brFlag
    },
    {
        id: 'nl', phoneSystemId: 'NL', value: 'Nederlands', data: [nlLang, nlLinks], flag: nlFlag
    },
    {
        id: 'ru', phoneSystemId: 'RU', value: 'Русский', phoneLinks: ruPhoneLinks, data: [ruLang, ruAdminLang, ruLinks], flag: ruFlag
    },
    {
        id: 'pl', phoneSystemId: 'PL', value: 'Polski', data: [plLang], flag: plFlag
    },
    {
        id: 'zh-cn', phoneSystemId: 'ZH', value: '简体中文版', data: [zhLang, zhAdminLang], flag: cnFlag
    },
    {
        id: 'tr', phoneSystemId: 'TR', value: 'Türkçe', data: [trLang], flag: trFlag
    },
    {
        id: 'cs', phoneSystemId: 'CS', value: 'Český', data: [csLang], flag: czFlag
    },
];

export function initLangs(translate: TranslateService) {
    // Load all required locales
    [enGb, br, de, fr, it, es, zh, ru, nl, tr, pl, cs].forEach(
        locale => dayjs.locale(locale.name, locale));
    // Made dayjs locale English again
    dayjs.locale('en');

    // this language will be used as a fallback
    // when a translation isn't found in the current language
    const languageId = languageList.map(x => x.id);
    translate.addLangs(languageId);
    // translate.setDefaultLang('en');
}
